<template>
  <div>
    <div class="flex justify-between mt-8">
      <h1 class="text-13 text-gray-700 font-normal">
        Categories
      </h1>
      <router-link
        :to="{ name: 'vendors-category' }"
        class="text-13 text-blue-500 inline-flex font-normal"
      >
        View all categories
        <ion-icon
          name="chevron-forward-outline"
          class="mt-1 text-blue-500"
        ></ion-icon>
      </router-link>
    </div>

    <div class="grid grid-cols-2 gap-5 md:grid-cols-3 md:w-full mt-5 md:mt-6">
      <div
        class="
          bg-cover border border-ash-900 cursor-pointer duration-300
          flex flex-col h-130px p-3 rounded text-left transform
          hover:-translate-y-1
        "
        v-for="(category, i) in categories.data"
        :key="i"
        :style="{
          'background-image': 'url(' + category.image + ')',
        }"
        @click="filterWithCategory(category)"
      >
        <div
          class="text-13 text-white font-extrabold"
          v-text="category.name"
        ></div>
      </div>
    </div>
    <no-result v-if="noCategoryFound">
      No result found for this category
    </no-result>
  </div>
</template>
<script>
export default {
  components: {
    NoResult: () => import("@/views/app/marketplace/components/NoResult")
  },
  data() {
    return {
      categories: this.$options.resource([]),
    };
  },
  computed: {
    noCategoryFound() {
      return !this.categories.loading && this.categories?.data?.length === 0;
    },
  },
  beforeMount() {
    this.getAllCategories();
  },
  methods: {
    async getAllCategories() {
      this.categories.loading = true;
      await this.sendRequest(
        "personal.marketplace.categories", 6, "", ['featured'], {
        success: (response) =>
          (this.categories.data = response.data.datatable.data),
        error: (error) => console.log(error),
      });
      this.categories.loading = false;
    },
    filterWithCategory({ id }) {
      this.$router.push({
        name: "category-vendors",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>
